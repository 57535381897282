<template>
  <div id="school-fee" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <div class="columns is-centered">
            <div class="column is-7">
              <p class="subtitle is-6">Pay School Fee</p>
              <ValidationObserver ref="observer" v-slot="{ passes }">
                <form @submit.prevent="passes(submit)">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <SdInput
                        type="text"
                        label="Name"
                        v-model="student.name"
                        disabled
                      />
                    </div>
                    <div class="column is-12">
                      <SdInput
                        type="text"
                        label="Reg ID"
                        v-model="student.regId"
                        disabled
                      />
                    </div>
                    <template v-if="student.schoolFeeSummary">
                      <template v-if="displayStudentList">
                        <div class="column is-4">
                          <SdInput
                            type="text"
                            label="Total Fee"
                            v-model="student.schoolFeeSummary.total_fee"
                            disabled
                          />
                        </div>
                        <div class="column is-4">
                          <SdInput
                            type="text"
                            label="Total Paid"
                            v-model="student.schoolFeeSummary.total_paid"
                            disabled
                          />
                        </div>
                        <div class="column is-4">
                          <SdInput
                            type="text"
                            label="Amount Due"
                            v-model="student.schoolFeeSummary.amount_due"
                            disabled
                          />
                        </div>
                      </template>
                      <template v-else>
                        <div class="column is-12">
                          <SdInput
                            type="text"
                            label="Amount Due"
                            v-model="student.schoolFeeSummary.amount_due"
                            disabled
                          />
                        </div>
                      </template>
                      <div class="column is-12">
                        <SdInput
                          type="text"
                          rules="required|double:2"
                          label="Amount Paid"
                          v-model="student.schoolFeeSummary.amount_paid"
                        />
                      </div>
                      <div class="column is-12">
                        <SdInput
                          type="text"
                          rules="min_value:0"
                          label="Balance"
                          v-model="student.schoolFeeSummary.balance"
                          disabled
                        />
                      </div>
                      <div class="column is-12">
                        <SdSelect
                          rules="required"
                          label="Transaction Type"
                          v-model="student.schoolFeeSummary.acctTransactionType"
                          :disabled="readonlyAcctTransactionType"
                        >
                          <option
                            v-for="acctTransactionType in acctTransactionTypes"
                            :key="acctTransactionType.name"
                          >
                            {{ acctTransactionType.name }}
                          </option>
                        </SdSelect>
                      </div>
                    </template>
                    <div class="column is-12">
                      <button
                        type="submit"
                        class="button btn-120 is-primary is-capitalized is-pulled-right"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'
import { toCurrency } from '@/assets/js/currency_formatter'
const randomstring = require('randomstring')

export default {
  name: 'SchoolFee',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Pay School Fee',
      studentSchoolFee: {},
      student: {
        schoolFeeSummary: {
          total_fee: 0,
          total_paid: 0,
          amount_due: 0,
          amount_paid: 0,
          balance: 0,
        },
      },
      initialAmountPaid: 0,
      acctTransactionTypes: [{ name: 'Cash' }, { name: 'Transfer' }],
      readonlyAcctTransactionType: false,
      displayStudentList: true,
    }
  },
  watch: {
    'student.schoolFeeSummary.amount_paid'(value) {
      if (!this.student.schoolFeeSummary) return
      if (this.$route.params.id === 'new') {
        if (!this.student.schoolFeeSummary.amount_paid) return
        this.student.schoolFeeSummary.balance =
          this.student.schoolFeeSummary.amount_due -
          this.student.schoolFeeSummary.amount_paid
      } else {
        this.student.schoolFeeSummary.balance =
          this.student.schoolFeeSummary.amount_due -
          this.student.schoolFeeSummary.amount_paid

        this.student.schoolFeeSummary.total_paid =
          this.student.schoolFeeSummary.total_fee -
          this.student.schoolFeeSummary.balance
      }
    },
    studentSchoolFee(data) {
      this.student.id = data.student.id
      this.student.name = data.student.name
      this.student.regId = data.student.regId
      this.student.schoolClassId = data.student.schoolClassId
      this.initialAmountPaid = data.amountPaid
      this.student.schoolFeeSummary = {
        total_fee: data.student.schoolFeeSummary.total_fee,
        amount_due: data.amountPaid + data.balance,
        total_paid: data.student.schoolFeeSummary.total_paid,
        amount_paid: data.amountPaid,
        balance: data.balance,
        acctTransactionType: data.acctTransactionType,
      }
    },
  },
  methods: {
    reset() {
      this.student = {}
    },
    fetchSchoolFee(student) {
      if (
        student.schoolFeeSummary.total_fee !== 0 &&
        student.schoolFeeSummary.total_fee ===
          student.schoolFeeSummary.total_paid
      ) {
        this.$buefy.toast.open({
          message: 'Student has paid complete school fees',
          type: 'is-info',
        })
        this.student = {
          schoolFeeSummary: {
            total_fee: 0,
            total_paid: 0,
            amount_due: 0,
            amount_paid: 0,
            balance: 0,
          },
        }
        return
      }

      if (student.schoolFeeSummary.total_fee === 0) {
        this.$buefy.toast.open({
          message: 'Total school fee cannot be zero.',
          type: 'is-danger',
        })
        this.$router.push(`/school/${this.schoolId}/school_fees`)
      }

      this.student = student
      this.studentId = student.id
      this.student.schoolFeeSummary.total_paid =
        student.schoolFeeSummary.total_paid === 0
          ? '0'
          : student.schoolFeeSummary.total_paid
    },
    convertToCurrency(value) {
      return toCurrency(value)
    },
    submit() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createSchoolFee(
                $studentId: Int!
                $termId: Int!
                $reference: String!
                $acctTransactionType: String!
                $amount: Float!
                $amountPaid: Float!
                $balance: Float!
              ) {
                createSchoolFee(
                  input: {
                    studentId: $studentId
                    termId: $termId
                    reference: $reference
                    acctTransactionType: $acctTransactionType
                    amount: $amount
                    amountPaid: $amountPaid
                    balance: $balance
                  }
                ) {
                  schoolFee {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              studentId: parseInt(this.student.id),
              termId: parseInt(this.student.termId),
              reference: randomstring.generate(5),
              acctTransactionType: this.student.schoolFeeSummary
                .acctTransactionType,
              amount: parseFloat(this.student.schoolFeeSummary.amount_due),
              amountPaid: parseFloat(this.student.schoolFeeSummary.amount_paid),
              balance: parseFloat(this.student.schoolFeeSummary.balance),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createSchoolFee.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.replace(
                `/school/${this.schoolId}/school_class/${this.student.schoolClassId}/student_info/${this.student.id}`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateSchoolFee(
                $id: Int!
                $acctTransactionType: String!
                $amountPaid: Float!
                $balance: Float!
              ) {
                updateSchoolFee(
                  input: {
                    id: $id
                    acctTransactionType: $acctTransactionType
                    amountPaid: $amountPaid
                    balance: $balance
                  }
                ) {
                  schoolFee {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.$route.params.id),
              acctTransactionType: this.student.schoolFeeSummary
                .acctTransactionType,
              amountPaid: parseFloat(this.student.schoolFeeSummary.amount_paid),
              balance: parseFloat(this.student.schoolFeeSummary.balance),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateSchoolFee.errors,
              'Successfully updated.'
            ).then(() => {
              this.$store.commit('setValue', 1)
              this.$router.replace(
                `/school/${this.schoolId}/school_class/${this.student.schoolClassId}/student_info/${this.student.id}`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    commentColor(student) {
      const schoolFee = student.schoolFeeSummary

      if (schoolFee && schoolFee.total_fee !== 0) {
        if (schoolFee.total_fee === schoolFee.total_paid) {
          return 'green'
        } else if (
          schoolFee.total_fee !== schoolFee.total_paid &&
          schoolFee.total_paid > 0
        ) {
          return 'orange'
        } else {
          return 'red'
        }
      } else {
        return 'red'
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    if (this.$route.params.id === 'new') {
      const studentId = parseInt(this.$route.params.student_id)
      this.studentSchoolFee.id = studentId
      this.$apollo.addSmartQuery('student', {
        query: gql`
          query StudentSchoolFeeQuery($id: ID!) {
            student(id: $id) {
              id
              name
              regId
              schoolClassId
              termId
              schoolFeeSummary
              ateMarksAverageScore
              feeTemplate
              termSchoolFeePayments {
                id
                reference
                acctTransactionType
                amount
                amountPaid
                balance
              }
            }
          }
        `,
        fetchPolicy: 'cache-and-network',
        variables() {
          return { id: studentId }
        },
        error(error) {
          this.error = JSON.stringify(error.message)
        },
      })
    } else {
      this.readonlyAcctTransactionType = true

      this.$apollo.addSmartQuery('studentSchoolFee', {
        query: gql`
          query SchoolFeeQuery($id: ID!) {
            studentSchoolFee(id: $id) {
              id
              student {
                id
                name
                regId
                schoolClassId
                schoolFeeSummary
                feeTemplate
                termSchoolFeePayments {
                  id
                  reference
                  acctTransactionType
                  amount
                  amountPaid
                  balance
                }
              }
              amount
              amountPaid
              balance
              acctTransactionType
            }
          }
        `,
        fetchPolicy: 'cache-and-network',
        variables() {
          return { id: parseInt(this.$route.params.id) }
        },
        error(error) {
          this.error = JSON.stringify(error.message)
        },
      })
    }

    this.$store.commit('setSubMenus', [
      {
        name: 'School Fees',
        route: `/school/${this.schoolId}/school_fees`,
      },
    ])
  },
}
</script>
